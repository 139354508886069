// Import the functions you need from the SDKs you need
import {initializeApp} from "@firebase/app";
import {FacebookAuthProvider, getAuth, GoogleAuthProvider} from "@firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBxCug2te-kYeft6914VBWHWLR_VPeruxs",
    authDomain: "rappit.firebaseapp.com",
    databaseURL: "https://rappit.firebaseio.com",
    projectId: "rappit",
    storageBucket: "rappit.appspot.com",
    messagingSenderId: "660396343599",
    appId: "1:660396343599:web:72facb1ef3588f0deaa1da",
    measurementId: "G-QY7CRT1LVN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();


// export const db = app.firestore();

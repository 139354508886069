import React from 'react';
import {Container} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {LoginWidget} from "../components/login";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '20px',
        width: '100%',
    },
}));

export function LoginPage({signup}) {
    const classes = useStyles();
    let onSuccess = () => {
        if (window.location.search === '?redirect=marketplace-registration') {
            window.location = 'http://localhost:3000/register';
        }
    };
    return (
        <Container className={classes.root} title={"Login"} maxWidth={"sm"}>
            <LoginWidget signup={signup} onSuccess={onSuccess}/>
        </Container>
    );
}

import React from 'react';
import {Container, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        // position: "relative"
    },
}));

export function HomePage() {
    const classes = useStyles();
    return (
        <Container className={classes.root}>
            {/*<Box>*/}
            <Typography variant={"h3"} fontWeight={200}>Monetiza tu audiencia y vende por redes sociales en tan solo 5
                minutos.</Typography>
            <Typography variant={"h5"} fontWeight={200}>Baja el app, responde 3 preguntas, agrega tus productos y
                empieza a
                vender.</Typography>
            {/*</Box>*/}
        </Container>
    );
}

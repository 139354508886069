import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";
import rappitTheme from "./theme"
import {Header} from "./components/header";
import {HomePage} from "./views/home";
import {LoginPage} from "./views/login";
import {AuthProvider} from "./authContext";

const theme = createTheme(rappitTheme);

ReactDOM.render(
    <React.StrictMode>
        <AuthProvider>
            <ThemeProvider theme={theme}>
                <Router>
                    <Switch>
                        <Route exact path="/login"><LoginPage/></Route>
                        <Route exact path="/signup"><LoginPage signup/></Route>
                        <Header/>
                        <Route exact path="/"><HomePage/></Route>
                    </Switch>
                </Router>
            </ThemeProvider>
        </AuthProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.unregister();

import React, {useContext} from 'react';
import {AppBar, Box, Button, Hidden, IconButton, Toolbar} from "@mui/material";
import {makeStyles} from "@mui/styles";
import logo from "../logo-simple-white.svg";
import {AccountCircle, Logout, Menu} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {auth} from "../firebase";
import {AuthContext} from "../authContext";

const useStyles = makeStyles((theme) => ({
    root: {},
    menuCollapseButton: {
        color: 'inherit',
    },
    logo: {
        marginRight: theme.spacing(4),
        marginLeft: theme.spacing(4)
    },
    title: {
        flexGrow: 1,
        marginRight: theme.spacing(10)
    },
    appBar: {
        boxShadow: "none",
        borderBottom: "0",
        marginBottom: "0",
        position: "fixed",
        width: "100%",
        border: "0",
        transition: "all 150ms ease 0s",
        display: "block"
    },
}));

export function Header() {
    const classes = useStyles();
    const {user} = useContext(AuthContext);
    return (
        <React.Fragment>
            <AppBar position="static" variant={"elevation"} elevation={0}>
                <Toolbar>
                    <Box className={classes.logo}>
                        <Link to={'/'}><img src={logo} alt={"rappit pay"}/></Link>
                    </Box>
                    <Hidden smDown>
                        <Button className={classes.title} color={"inherit"}>rappit</Button>
                        <Button className={classes.title} color={"inherit"}>Como Funciona</Button>
                        <Button className={classes.title} color={"inherit"}>Servicios</Button>
                        <Button className={classes.title} color={"inherit"}>Comision</Button>
                        <Button className={classes.title} color={"inherit"}>Contacto</Button>
                        {user ?
                            <Box className={classes.title}>
                                <Button component={Link} to={"/account"} style={{marginRight: '10px'}}
                                        variant={"outlined"}
                                        color={"inherit"} startIcon={
                                    <AccountCircle/>}>{user.displayName}</Button>
                                <Button onClick={() => auth.signOut()} style={{marginRight: '10px'}}
                                        variant={"outlined"}
                                        color={"inherit"} startIcon={<Logout/>}>Logout</Button>
                            </Box>
                            :
                            <Box className={classes.title}>
                                <Button component={Link} to={"/login"} style={{marginRight: '10px'}}
                                        variant={"outlined"}
                                        color={"inherit"}>Login</Button>
                                <Button component={Link} to={"/signup"} style={{marginRight: '10px'}}
                                        variant={"outlined"}
                                        color={"inherit"}>Signup</Button>
                            </Box>
                        }
                    </Hidden>
                    <Hidden mdUp>
                        <Box className={classes.title}>
                            <IconButton
                                size="large"
                                edge="end"
                                color="inherit"
                                aria-label="menu"
                                className={classes.menuCollapseButton}
                            >
                                <Menu/>
                            </IconButton>
                        </Box>
                    </Hidden>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}

import React, {useState} from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Container,
    FormControl,
    FormHelperText,
    Input,
    InputLabel,
    Snackbar
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {AccountCircle, Facebook, Google} from "@mui/icons-material";
import {auth, googleProvider} from '../firebase';
import {createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup} from "@firebase/auth";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "400px",
        maxWidth: '500px'
    },
}));


export function LoginWidget({signup, onSuccess}) {
    const classes = useStyles();
    const [signupView, setSignupView] = useState(signup);
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(false);
    const [user, setUser] = useState(false);
    const [name, setName] = useState(false);
    const [email, setEmail] = useState(false);
    const [password, setPassword] = useState(false);
    const [repeatPassword, setRepeatPassword] = useState(false);

    const signInWithEmail = async (email, password) => {
        try {
            const res = await signInWithEmailAndPassword(auth, email, password);
            setUser(res.user);
            onSuccess && onSuccess();
        } catch (err) {
            setError(err.message);
        }
    };

    const registerWithEmailAndPassword = async (name, email, password) => {
        try {
            const res = await createUserWithEmailAndPassword(auth, email, password);
            setUser(res.user);
            onSuccess && onSuccess();
        } catch (err) {
            setError(err.message);
        }
    };

    const signInWithGoogle = async () => {
        try {
            const res = await signInWithPopup(auth, googleProvider);
            setUser(res.user);
            onSuccess && onSuccess();
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <React.Fragment>
            <Card variant={"outlined"} className={classes.root} hidden={signupView}>
                <CardHeader title={"Login"}/>
                <CardContent>
                    <Container fixed>
                        <FormControl margin={"normal"} fullWidth>
                            <InputLabel>E-Mail</InputLabel>
                            <Input type={'email'}/>
                            <FormHelperText>Your account E-Mail</FormHelperText>
                        </FormControl>
                        <FormControl margin={"normal"} fullWidth>
                            <InputLabel>Password</InputLabel>
                            <Input type={'password'}/>
                            <FormHelperText>Your password</FormHelperText>
                        </FormControl>
                        <Snackbar message={error} hidden={!error}/>
                    </Container>
                    <CardActions>
                        <FormControl margin={"normal"} fullWidth>
                            <Button disabled={processing} startIcon={processing ? <CircularProgress size={25}/> : null}
                                    size={"large"} variant={"contained"}>Login</Button>
                        </FormControl>
                    </CardActions>
                </CardContent>
                <CardContent>
                    <Container fixed>
                        <InputLabel>Or access with:</InputLabel>
                        <FormControl margin={"normal"} fullWidth>
                            <Button onClick={() => {
                                signInWithGoogle()
                            }} startIcon={<Google/>} variant={"outlined"}>Google</Button>
                        </FormControl>
                        <FormControl margin={"normal"} fullWidth>
                            <Button startIcon={<Facebook/>} variant={"outlined"}>Facebook</Button>
                        </FormControl>
                        <FormControl margin={"normal"} fullWidth>
                            <Button onClick={() => {
                                setSignupView(true)
                            }} startIcon={<AccountCircle/>} variant={"outlined"}>Create account</Button>
                        </FormControl>
                    </Container>
                </CardContent>
            </Card>

            <Card variant={"outlined"} className={classes.root} hidden={!signupView}>
                <CardHeader title={"Registration"}/>
                <CardContent>
                    <Container fixed>
                        <FormControl required margin={"normal"} fullWidth>
                            <InputLabel>Name</InputLabel>
                            <Input type={'text'} onBlur={setName}/>
                            <FormHelperText>Your full name</FormHelperText>
                        </FormControl>
                        <FormControl required margin={"normal"} fullWidth>
                            <InputLabel>E-Mail</InputLabel>
                            <Input type={'email'} onBlur={setEmail}/>
                            <FormHelperText>Your E-Mail</FormHelperText>
                        </FormControl>
                        <FormControl margin={"normal"} fullWidth>
                            <InputLabel>New Password</InputLabel>
                            <Input type={'password'} onBlur={setPassword}/>
                            <FormHelperText>Your new password</FormHelperText>
                        </FormControl>
                        <FormControl margin={"normal"} fullWidth>
                            <InputLabel>Repeat Password</InputLabel>
                            <Input type={'password'} onBlur={setRepeatPassword}/>
                            <FormHelperText>Repeat new password</FormHelperText>
                        </FormControl>
                    </Container>
                    <CardActions>
                        <FormControl margin={"normal"} fullWidth>
                            <Button disabled={processing} startIcon={processing ? <CircularProgress size={25}/> : null}
                                    onClick={() => registerWithEmailAndPassword(name, email, password)} size={"large"} variant={"contained"}>Sign-up now</Button>
                        </FormControl>
                    </CardActions>
                </CardContent>
                <CardContent>
                    <Container fixed>
                        <InputLabel>Or access with:</InputLabel>
                        <FormControl margin={"normal"} fullWidth>
                            <Button onClick={() => {
                                signInWithGoogle()
                            }} startIcon={<Google/>} variant={"outlined"}>Google</Button>
                        </FormControl>
                        <FormControl margin={"normal"} fullWidth>
                            <Button startIcon={<Facebook/>} variant={"outlined"}>Facebook</Button>
                        </FormControl>
                    </Container>
                </CardContent>
                <CardContent>
                    <Container fixed>
                        <InputLabel>Already have an account?</InputLabel>
                        <FormControl margin={"normal"} fullWidth>
                            <Button onClick={() => {
                                setSignupView(false)
                            }} startIcon={<AccountCircle/>} variant={"outlined"}>Login</Button>
                        </FormControl>
                    </Container>
                </CardContent>
            </Card>
        </React.Fragment>
    );
}